'use client';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ProductsType } from "@/interFace/interFace";
import { toast } from "sonner";

// Define the state for the wishlist
interface WishlistState {
  wishlistProducts: ProductsType[];
}

// Initial state
const initialState: WishlistState = {
  wishlistProducts: [],
};

// Define the wishlist slice
export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    // Add or increase quantity of a product in the wishlist
    wishlist_product: (state, { payload }: PayloadAction<ProductsType>) => {
      // Ensure wishlistProducts is defined and is an array
      if (!Array.isArray(state.wishlistProducts)) {
        state.wishlistProducts = []; // Initialize if it's undefined or not an array
      }

      // Ensure the payload is valid and has an id
      if (!payload?.id) {
        console.error("Invalid product payload: Missing ID");
        return; // Exit the reducer early if payload is invalid
      }

      const productIndex = state.wishlistProducts.findIndex(
        (item) => item.id === payload.id
      );

      if (productIndex >= 0) {
        // If product already exists, increase its quantity
        state.wishlistProducts[productIndex].totalCart! += 1;
        const toastId = toast.loading("");
        toast.info("Increased Product wishlist Quantity", {
          id: toastId,
          duration: 1000,
        });
      } else {
        // If product is new, add it to the wishlist
        const now = moment();
        const orderDate = now.format("MM/DD/YY hh:mm a");

        const tempProduct = {
          ...payload,
          totalCart: 1,
          orderDate: orderDate,
        };

        state.wishlistProducts.push(tempProduct);

        const capitalizedProductName =
          payload.name.charAt(0).toUpperCase() + payload.name.slice(1);

        const toastId = toast.loading("");
        toast.success(`${capitalizedProductName} added to wishlist`, {
          id: toastId,
          duration: 1000,
        });
      }
    },

    // Remove a product from the wishlist
    remove_wishlist_product: (
      state,
      { payload }: PayloadAction<ProductsType>
    ) => {
      state.wishlistProducts = state.wishlistProducts.filter(
        (item) => item.id !== payload.id
      );

      const toastId = toast.loading("");
      toast.error(`${payload.name} removed from your wishlist`, {
        id: toastId,
        duration: 1000,
      });
    },

    // Clear all wishlist items
    clear_wishlist: (state) => {
      const confirmMsg = window.confirm(
        "Are you sure you want to delete all wishlist items?"
      );
      if (confirmMsg) {
        state.wishlistProducts = [];
        toast.success("Wishlist cleared");
      }
    },

    // Decrease the quantity of a product in the wishlist
    decrease_quantity: (state, { payload }: PayloadAction<ProductsType>) => {
      const productIndex = state.wishlistProducts.findIndex(
        (item) => item.id === payload.id
      );

      if (productIndex >= 0) {
        const totalCart = state.wishlistProducts[productIndex]?.totalCart ?? 0;

        if (totalCart > 1) {
          state.wishlistProducts[productIndex].totalCart = totalCart - 1;
          const toastId = toast.loading("");
          toast.success("Quantity Decreased", {
            id: toastId,
            duration: 1000,
          });
        } else {
          const toastId = toast.loading("");
          toast.error("Quantity cannot be less than 1", {
            id: toastId,
            duration: 1000,
          });
        }
      }
    },
  },
});

// Export actions
export const {
  wishlist_product,
  remove_wishlist_product,
  clear_wishlist,
  decrease_quantity,
} = wishlistSlice.actions;

// Export reducer
export default wishlistSlice.reducer;
