import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ProductsType } from "@/interFace/interFace"; // Adjust this import if necessary
import { toast } from "sonner";

interface CartState {
  cartProducts: ProductsType[];
}

const initialState: CartState = {
  cartProducts: [], // Initialize with an empty array
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cart_product: (state, { payload }: PayloadAction<ProductsType>) => {
      const { id, size, quantity } = payload;
      const productIndex = state.cartProducts.findIndex(
        (item) => item.id === id && item.size === size
      );

      if (productIndex >= 0) {
        // Update the quantity of an existing product in the cart
        state.cartProducts[productIndex].totalCart! += quantity;
        toast.info(`Increased ${payload.name} quantity to ${state.cartProducts[productIndex].totalCart}`, { duration: 1000 });
      } else {
        const now = moment();
        const orderDate = now.format("MM/DD/YY hh:mm a");

        const tempProduct = {
          ...payload,
          totalCart: quantity,  // Set the quantity directly
          orderDate: orderDate,
        };
        state.cartProducts.push(tempProduct);

        const capitalizedProductName =
          payload.name.charAt(0).toUpperCase() + payload.name.slice(1);
        toast.success(`${capitalizedProductName} added to cart`, { duration: 1000 });
      }
    },
    cart_group_product: (state, { payload }: PayloadAction<ProductsType[]>) => {
      payload.forEach((product) => {
        const productIndex = state.cartProducts.findIndex(
          (item) => item.id === product.id && item.size === product.size
        );
        if (productIndex >= 0) {
          state.cartProducts[productIndex].totalCart! += product.quantity;
          toast.info("Increased Product Quantity", { duration: 1000 });
        } else {
          const now = moment();
          const orderDate = now.format("MM/DD/YY hh:mm a");

          const tempProduct = {
            ...product,
            totalCart: product.quantity,  // Set the quantity directly
            orderDate: orderDate,
          };
          state.cartProducts.push(tempProduct);

          const capitalizedProductName =
            product.name.charAt(0).toUpperCase() + product.name.slice(1);
          toast.success(`${capitalizedProductName} added to cart`, { duration: 1000 });
        }
      });
    },
    remove_cart_product: (state, { payload }: PayloadAction<ProductsType>) => {
      state.cartProducts = state.cartProducts.filter(
        (item) => item.id !== payload.id || item.size !== payload.size
      );
      toast.error(`Removed from your cart`, { duration: 1000 });
    },
    clear_cart: (state) => {
      const confirmMsg = window.confirm("Are you sure you want to delete all cart items?");
      if (confirmMsg) {
        state.cartProducts = [];
        toast.success("Cart cleared", { duration: 1000 });
      }
    },
    clearCartWithoutConfirmation: (state) => {
      state.cartProducts = [];
      toast.success("Cart cleared", { duration: 1000 });
    },
    decrease_quantity: (state, { payload }: PayloadAction<ProductsType>) => {
      const cartIndex = state.cartProducts.findIndex(
        (item) => item.id === payload.id && item.size === payload.size
      );

      if (cartIndex >= 0) {
        const totalCart = state.cartProducts[cartIndex]?.totalCart ?? 0;

        if (totalCart > 1) {
          state.cartProducts[cartIndex].totalCart = totalCart - 1;
          toast.success("Quantity Decreased", { duration: 1000 });
        } else {
          toast.error("Quantity cannot be less than 1", { duration: 1000 });
        }
      }
    },
  },
});

export const {
  cart_product,
  cart_group_product,
  remove_cart_product,
  clear_cart,
  clearCartWithoutConfirmation,
  decrease_quantity,
} = cartSlice.actions;

export default cartSlice.reducer;
